<script setup lang="ts">
import { type CollectionQuery } from '~/generated/operations'

type Collection = Extract<
	CollectionQuery['entry'],
	{
		__typename: `collection_featured_Entry`
	}
>

type FeaturedCollection = Extract<
	Collection['featuredToPublication'][0],
	{
		__typename: `publicaties_${string}`
	}
>

const props = defineProps<{
	publications: FeaturedCollection[]
	title?: string
	description?: string
}>()

let showText = props.title || props.description
const value = reactive({ translateX: 0 })
const { set } = useSpring(value, {
	damping: 45,
	stiffness: 400,
})

const setTranslateX = (x: number) => set({ translateX: x })
</script>

<template>
	<div class="featured-carrousel">
		<div
			v-if="showText"
			class="featured-carrousel__intro"
			ref="intro"
			:style="{
				transform: `translateX(${value.translateX}px)`,
			}"
		>
			<div class="featured-carrousel__intro__text">
				<CollectionText
					:title="title"
					:text="description"
					:html="true"
				/>
			</div>
		</div>

		<CollectionFeaturedCarrousel
			:publications="props.publications"
			:adjust-x="showText ? true : false"
			@set-translate-x="setTranslateX"
		/>
	</div>
</template>

<style scoped>
.featured-carrousel {
	@apply relative sm:h-[500px];
}

.featured-carrousel__intro {
	@apply md:absolute md:flex flex-col justify-center items-center md:h-full md:w-[45%] mb-6 md:mb-0;
}

.featured-carrousel__intro__text {
	@apply w-[440px] max-w-full px-8 mx-auto md:mx-0;
}
</style>
