<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import type SwiperType from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { PublicationSummary } from '~/components/card/index.vue'

// Import Swiper styles
import 'swiper/css'

const props = defineProps<{
	publications: PublicationSummary[]
	adjustX?: boolean
}>()

const emit = defineEmits<{
	(e: 'setTranslateX', x: number): void
}>()

const breakpoints = useBreakpoints(breakpointsTailwind)

const offsetBefore = ref(0)
const offsetAfter = ref(0)
const spaceBetween = ref(5)
const useCenteredSlides = ref(false)

const onResize = () => {
	const isMobile = breakpoints.isSmaller('md')
	offsetBefore.value = isMobile
		? 0
		: !props.adjustX
		? 50
		: (document.body.clientWidth / 2) * 0.9
	offsetAfter.value = isMobile ? 0 : !props.adjustX ? 50 : 200
	spaceBetween.value = isMobile ? 0 : 5
	useCenteredSlides.value = isMobile
}

onMounted(onResize)

const onSliderMove = (s: SwiperType, progress: number) => {
	const diff = s.translate - offsetBefore.value
	const x = progress === 0 ? 0 : diff
	emit('setTranslateX', breakpoints.isSmaller('md') || !props.adjustX ? 0 : x)
}

const onSlideChange = (s: SwiperType) => {
	emit(
		'setTranslateX',
		breakpoints.isSmaller('md') || !props.adjustX
			? 0
			: s.snapGrid[0] - s.snapGrid[s.activeIndex],
	)
}
</script>

<template>
	<swiper
		class="cards"
		:slidesPerView="'auto'"
		:centeredSlides="useCenteredSlides"
		:spaceBetween="spaceBetween"
		:onResize="onResize"
		:onProgress="onSliderMove"
		:slidesOffsetBefore="offsetBefore"
		:slidesOffsetAfter="offsetAfter"
		:onSlideChange="onSlideChange"
	>
		<swiper-slide v-for="publication in publications" class="card">
			<Card :publication="publication" />
		</swiper-slide>

		<MiscSwiperPagination :type="'dotted'" />
	</swiper>
</template>

<style scoped>
.cards {
	@apply w-full !overflow-y-visible;
}

.card {
	@apply !w-auto p-2.5 md:p-5;
	& > .card {
		@apply !p-0;
	}
}
</style>
